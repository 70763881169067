<template>
  <dxp-page
    :class="['DxpDefaultLayout', isLoading && 'DxpDefaultLayout--is-loading']"
    :isHome="isEmptyBody"
  >
    <mom-spinner class="DxpDefaultLayout__Spinner" v-if="isLoading" />
    <template v-else>
      <mom-layout-wrapper v-if="showBreadcrumbs" class="DxpDefaultLayout__Main">
        <mom-breadcrumb
          class="DxpDefaultLayout__Breadcrumb"
          responsive
          :links="breadcrumbLinks"
          :previousText="breadcrumbMobileText"
        />
        <mom-horizontal-line class="DxpDefaultLayout__Breadcrumb__Line" />
      </mom-layout-wrapper>
      <mom-layout-wrapper v-if="mobileNavigationList">
        <div class="DxpDefaultLayout__Navbar--mobile">
          <mom-input-select
            idForInput="mobile_navbar"
            placeholder="Select an option"
            :value="mobileNavInput"
            :options="mobileNavigationList"
            @input="goToLinkMobile($event)"
          >
          </mom-input-select>
        </div>
      </mom-layout-wrapper>
      <div v-if="isEmptyBody">
        <slot></slot>
      </div>
      <mom-layout-wrapper v-else>
        <div class="DxpDefaultLayout__Content">
          <div class="DxpDefaultLayout__Navbar" v-if="withNavigationBar">
            <dxp-nav-bar
              :itemList="navigationList"
              @click="setCurrentTab"
            ></dxp-nav-bar>
          </div>
          <div
            :class="[
              'DxpDefaultLayout__TextContent',
              withNavigationBar && 'DxpDefaultLayout__Navbar__TextContent',
            ]"
          >
            <div class="DxpDefaultLayout__MainContent">
              <!-- @slot Main Content -->
              <slot></slot>
            </div>
            <div v-if="showPageNav">
              <div class="DxpDefaultLayout__DxpPageNav">
                <div class="DxpDefaultLayout__DxpPageNav__Wrapper">
                  <mom-link
                    text="Previous"
                    icon="chevron-left"
                    icon-position="left"
                    class="DxpDefaultLayout__DxpPageNav__LeftLink"
                    :href="pagenav.leftLink"
                  />
                  <mom-link
                    text="Next"
                    icon="chevron-right"
                    icon-position="right"
                    class="DxpDefaultLayout__DxpPageNav__RightLink"
                    :href="pagenav.rightLink"
                  />
                </div>
                <br />
                <div class="DxpDefaultLayout__DxpPageNav__WrapperText">
                  <mom-link
                    :text="pagenav.leftLinkName"
                    class="DxpDefaultLayout__DxpPageNav__LeftLink"
                    :href="pagenav.leftLink"
                  />
                  <mom-link
                    :text="pagenav.rightLinkName"
                    class="DxpDefaultLayout__DxpPageNav__RightLink"
                    :href="pagenav.rightLink"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </mom-layout-wrapper>
    </template>
    <dxp-page-header
      class="DxpDefaultLayout__Header"
      showMore
      slot="header"
      :tabs="headerTabs"
      :moreOptions="headerTabMore"
      :activeTabIndex="currentHeaderTab"
      @search="onSearch"
    >
    </dxp-page-header>
    <dxp-page-footer
      v-if="!isLoading"
      class="DxpDefaultLayout__Footer"
      slot="footer"
      last-updated-date="19 December 2024"
      :sitemap="footerSitemap"
      :footerOthers="footerOthers"
      :mom-footer-sitemap="momFooterSitemap"
    >
      <dxp-page-feedback
        slot="precard"
        :title="footerFeedbackTitle"
        @click="onFeedback"
      />
    </dxp-page-footer>
  </dxp-page>
</template>

<script>
// @ is an alias to /src
import DxpPage from "@/components/page/DxpPage.vue";
import DxpPageHeader from "@/components/page/DxpPageHeader.vue";
import DxpPageFooter from "@/components/page/DxpPageFooter.vue";
import DxpNavBar from "@/components/container/DxpNavBar.vue";
import DxpPageFeedback from "@/components/page/DxpPageFeedback.vue";
import { MomLayoutWrapper, MomHorizontalLine } from "cdpl/layout.esm";
import { MomBreadcrumb } from "cdpl/breadcrumb.esm";
import { MomInputSelect } from "cdpl/input_select.esm";
import { MomSpinner } from "cdpl/spinner.esm";
import { headerConfig, footerConfig } from "@/constants/pageConfig";
import { MomLink } from "cdpl/link.esm";
import escapeString from "@/utils/escapeString";
export default {
  name: "DxpDefaultLayout",
  release: "0.1.1",
  lastUpdated: "0.1.1",
  components: {
    DxpPage,
    DxpPageHeader,
    DxpPageFooter,
    DxpPageFeedback,
    DxpNavBar,
    MomLayoutWrapper,
    MomHorizontalLine,
    MomBreadcrumb,
    MomInputSelect,
    MomLink,
    MomSpinner,
  },
  props: {
    /**
     * Show navigation and breadcrumb if not empty
     */
    isEmptyBody: {
      type: Boolean,
      default: false,
    },
    /**
     * Show left navigation bar
     */
    withNavigationBar: {
      type: Boolean,
      default: false,
    },
    /**
     * Show page navigation bar at bottom
     */
    showPageNav: {
      type: Boolean,
      default: false,
    },
    /**
     * Navigation bar list
     */
    navigationList: {
      type: Array,
    },
    /**
     * Breadcrumb list
     */
    showBreadcrumbs: {
      type: Boolean,
      default: true,
    },
    /**
     * Breadcrumb list
     */
    breadcrumbLinks: {
      type: Array,
    },
    /**
     * Breadcrumb text for mobile
     */
    breadcrumbMobileText: {
      type: String,
      default: "Back",
    },
    /**
     * Title for footer feedback section
     */
    footerFeedbackTitle: {
      type: String,
      default: "Have any questions or feedback?",
    },
    /**
     * Header Tab Id active
     */
    currentHeaderTab: {
      type: [Number, String],
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      mobileNavInput: "",
      pagenav: {
        leftLink: "/",
        leftLinkName: "Previous",
        rightLink: "/",
        rightLinkName: "Next",
      },
      isLoading: true,
    };
  },
  computed: {
    mobileNavigationList() {
      if (this.navigationList)
        return this.navigationList.map((x) => ({
          label: x.title,
          value: x.href.split("#")[1],
        }));
      return null;
    },
    headerTabs() {
      return headerConfig.headerTabs;
    },
    headerTabMore() {
      return headerConfig.headerTabMore;
    },
    footerSitemap() {
      return footerConfig.footerSitemap;
    },
    footerOthers() {
      return footerConfig.footerOthers;
    },
    momFooterSitemap() {
      return footerConfig.momFooterSitemap;
    },
  },
  methods: {
    goToLinkMobile(e) {
      this.$router.push(e);
    },
    onSearch(searchValue) {
      const encodedSearchValue = encodeURI(escapeString(searchValue.trim()));
      this.$router.push("/search/" + encodedSearchValue);
    },
    onFeedback(e) {
      this.$emit("feedback", e);
    },
    setCurrentTab(e, tab) {
      if (this.navigationList)
        this.navigationList.forEach((item) => {
          if (item.href == tab.href) {
            item.isActive = true;
            if (!this.mobileNavInput)
              this.mobileNavInput = item.href.split("#")[1];
            this.updatePageNav(item);
          } else {
            if (item.children && item.children.length > 0) {
              item.isActive = false;
              item.children.forEach((element) => {
                if (element.href == tab.href) {
                  element.isActive = true;
                  item.isActive = true;
                  this.updatePageNav(element);
                } else {
                  element.isActive = false;
                }
              });
            } else {
              item.isActive = false;
            }
          }
        });
      this.$emit("navigate", e, tab);
    },
    updatePageNav(elem) {
      this.pagenav.leftLink = elem.leftLink;
      this.pagenav.leftLinkName = elem.leftLinkName;
      this.pagenav.rightLink = elem.rightLink;
      this.pagenav.rightLinkName = elem.rightLinkName;
    },
  },
  mounted() {
    this.setCurrentTab(null, {
      href: "#" + this.$route.path,
    });
    const self = this;
    this.$nextTick(() => {
      setTimeout(() => {
        self.isLoading = false;
      }, 10);
    });
  },
  updated() {
    this.setCurrentTab(null, {
      href: "#" + this.$route.path,
    });
  },
};
</script>

<style scoped lang="scss">
.DxpDefaultLayout {
  background-color: $color-white;
  // padding-top: rem(16);
  @include min-width(tablet) {
    padding-top: rem(32);
  }
  &--is-loading {
    height: 100vh;
  }
}
.DxpDefaultLayout__Heading {
  @include font-size(xl);
  @include line-height(xl);
  @include font-weight(semibold);
  padding-bottom: rem(16);
  @include min-width(tablet) {
    @include font-size(tablet-xl);
    @include line-height(tablet-xl);
  }
}
.DxpDefaultLayout__Paragraph {
  padding-right: rem(24);
  font-size: rem(30);
  @include font-weight(semibold);
}
.DxpDefaultLayout__Breadcrumb {
  margin-top: rem(0);
  margin-bottom: rem(0);
  padding: rem(0);
  @include min-width(tablet) {
    padding: rem(16) rem(0);
    margin: rem(0);
  }
}
.DxpDefaultLayout__Breadcrumb__Line {
  margin-top: rem(16) !important;
  margin-bottom: rem(16) !important;
  @include min-width(tablet) {
    display: block;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.DxpDefaultLayout__Content {
  display: block;
  @include min-width(tablet) {
    display: flex;
    flex-direction: row;
  }
}
.DxpDefaultLayout__Text {
  @include line-height(m);
}
.DxpDefaultLayout__FeedbackSection__Button {
  @include min-width(tablet) {
    margin-left: rem(64);
    margin-top: rem(0);
  }
  margin-left: rem(0);
  margin-top: rem(32);
  width: max-content;
}
.DxpDefaultLayout__Navbar {
  display: none;
  @include min-width(tablet) {
    flex-direction: column;
    display: flex;
    width: $sidebar-width;
    padding-top: rem(50);
  }
  &--mobile {
    display: flex;
    margin-bottom: rem(30);
    @include min-width(tablet) {
      display: none;
    }
  }
}
.DxpDefaultLayout__TextContent {
  flex: 1;
  @include min-width(tablet) {
    padding-top: rem(44);
  }
}

.DxpDefaultLayout__Navbar__TextContent {
  @include min-width(tablet) {
    margin-left: rem(40);
  }
}

.DxpDefaultLayout__DxpPageNav__LeftLink {
  float: left;
  width: min-content;
  @include min-width(tablet) {
    width: fit-content;
  }
}
.DxpDefaultLayout__DxpPageNav__RightLink {
  float: right;
  width: min-content;
  @include min-width(tablet) {
    width: fit-content;
  }
}
.DxpDefaultLayout__DxpPageNav__Wrapper {
  padding-bottom: rem(16);
}
.DxpDefaultLayout__DxpPageNav__WrapperText {
  @include font-weight(bold);
  > .DxpDefaultLayout__DxpPageNav__LeftLink {
    padding-left: rem(8) !important;
  }
  > .DxpDefaultLayout__DxpPageNav__RightLink {
    padding-right: rem(8) !important;
  }
}
.DxpDefaultLayout__Spinner {
  margin: auto;
  z-index: 1000;
  margin-top: rem(116);
}
</style>

<style lang="scss">
.DxpDefaultLayout__DxpPageNav__RightLink .MomLink__Text {
  text-align: right !important;
}
</style>
